
// Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap"; 
@import "node_modules/jquery-nice-select/scss/nice-select";
@import "node_modules/fullcalendar/dist/fullcalendar";

// Custom rules
@import "header";
@import "footer";
@import "forms";
@import "agenda";