.main-footer {
    h4, .address, .contact-info, .footer-menu, .newsletter, input, .btn {
        font-size: 14px; }
    .footer-summary {
        font-size: 13px;
        line-height: 1.2; }
    .btn-newsletter {
        background-color: #d98604;
        color: white;
        border-radius: 0; } }
.under-footer {
    font-size: 13px;
    border-top: 1px solid #4daaa7;
    .list-inline-item {
        margin-left: 0;
        margin-right: 0;
        &:not(:last-child) a::after {
            content: "|";
            margin-left: 5px;
            margin-right: 5px; } } }
