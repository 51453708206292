.agenda {
	.fitxa {
		.title {
			margin-top: 6px;
			margin-bottom: 5px;
			a {
				font-size: 17px;
				letter-spacing: 1px;
				color: black; } }
		.content {
			font-size: 14px;
			a {
				color: #023da8;
				font-weight: 600; } }
		.thumbnail {
			box-shadow: 0 0 29px -6px black; } }
	.details {
		header {
			border-bottom: 1px solid white;
			p {
				color: #848484;
				font-size: 15px;
				font-weight: 100;
				strong {
					font-weight: 600; } } }
		article.content {
			margin-top: 1em;
			p, a {
				font-size: 14px; }
			p {
				color: #848484; }
			a {
				font-weight: 600; } } }
	.links-box {
		color: #008581;
		border: 1px solid #7fc2c0;
		h3 {
			font-size: 18px;
			font-weight: 600; }
		ul {
			padding-left: 0;
			li {
				list-style: none;
				font-size: 15px;
				margin-bottom: 7px;
				&:before {
					display: none;
					font-family: "Font Awesome 5 Solid";
					content: "\f0c1"; }
				a {
					margin-left: .8em; } } } }
	.owl-theme .owl-dots .owl-dot span {
		background: #e5e5e5; }
	.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
		background: #008581; } }

.owl-carousel {
	position: relative;
	.item {
		position: relative; }
	img {
		position: relative; }
	p {
		position: absolute;
		font-size: 1.8rem;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); }
	.owl-controls {
		position: relative;
		top: 0;
		left: 0;
		transform: translate(0%, -100%);
		width: 100%; }
	.owl-nav {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%; }
	.owl-prev {
		float: left;
		background-color: white; }
	.owl-next {
		float: right; }
	.owl-prev, .owl-next {
		opacity: 0.5 !important;
		background-color: white !important;
		width: 45px;
		height: 45px;
		border-radius: 30px !important;
		color: #178f8a !important;
		font-size: 30px !important; }
	.owl-dots {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%); } }
