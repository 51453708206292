select.form-control {
    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
}

#titleimg{
    color: #666666;
    font-weight: bold;
}
.nopadding{
    padding: 0;
}
.nice-select{
    border-color: #008581;
    border-radius: 0px;
}
.nice-select:before {
    background-color: #008581;
    height: 100%;
    width: 15%;
    transform: rotate(0deg);
}
.nice-select:after{
    border:0;
    color: white;
    background-image: url("../images/angle-down.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
    text-align: center;
    position: absolute;
    background-color: #008581;
    height: 100%;
    width: 15%;
    transform: rotate(0deg);
    top: 0;
    right: 0;
    margin-top: 0;
}
.nice-select.open:after{
    transform: rotate(0deg);
}

.nice-select ul {
    width: 100%;
}