// Body

$primary: #008581;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$secondary: #ebebeb;
$font-family-base: 'Open Sans', sans-serif;

$theme-colors: (
  "gray": $gray-600,
  "gray-light": $gray-300,
);

$gray-900: #212529 !default;
$body-color: $gray-900 !default;

$hr-border-color: $primary;

// Paginations
$pagination-border-width: 0;
$pagination-hover-bg: transparent;
$pagination-color: $gray-600;
$pagination-active-color: $primary;
$pagination-active-bg: transparent;

// Forms
$input-border-color: $primary;
$enable-rounded: false;
$input-padding-y-sm: 0.25rem;
$input-padding-x-sm: 0.5rem;

// Spacers
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3)
);