#top-header {
    height: 45px;
    .lang-option {
        .nav-link {
            color: white;
            margin-left: 0;
            margin-right: 0; }
        &:not(:last-child) .nav-link::after {
            content: "|";
            margin-left: 5px;
            margin-right: 5px; } } }
#main-header {
    &.bg-opacity {
        background-color: rgba(255,255,255,0.9);
        position: absolute;
        z-index: 1; }
    .container {
        position: relative;
        .nav-item {
            &.active a.nav-link, &:hover a.nav-link {
                color: $primary !important; } }
        .weather-widgets {
            background: white;
            border: 1px solid $primary;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px; } }
    .dropdown:hover > .dropdown-menu {
        display: block;
        border-radius: 0;
        border: none;
        font-size: 14px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: -0.5em;
            bottom: 52px;
            left: 31%;
            box-sizing: border-box;
            border: 0.3em solid black;
            border-color: transparent transparent white white;
            transform-origin: 0 0;
            transform: rotate(135deg); }
        a {
            color: $body-color; } } }

.btnpaginator {
    height: 30px;
    width: 30px;
    background-color: black;
    border-radius: 200px; }

.hrstyle hr {
    margin-top: 4rem !important; }

.carousel-indicators {
    position: absolute;
    bottom: -50px !important;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center; }
